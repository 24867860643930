import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/407466686"
    notesSrc="http://bible.com/events/7186836"
    notesText="Sermon Notes"
    bibleGroupSrc="https://www.docdroid.net/BDxfNE1/bible-group-homework-4-12-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="The Miracle of the Resurrection - When Pigs Fly" />
  </Layout>
)

export default SermonPost
